import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  public static localStorage = {
    token: 'auth.token',
    token_expires_in: 'auth.token_expires_in',
    token_real: 'auth.token_real',
  };

  config: {
    api: any;
    sokrates?: any;
    upload?: any;
    system?: any;
    account?: any;
    local?: boolean;
    production?: boolean; };

  themes = ['88c0db', '76c0e3', '4ea8d4', '319fd4', '1b9ad7', '136e9a', '115c80', '0c4a6a', '0c3449'];

  constructor() {
    this.initConfig();
  }

  initConfig() {
    this.config = environment;
    this.setApiUrl(this.config.api.url, this.config.api.secure);
    this.setSokratesUrl(this.config.sokrates.url, this.config.sokrates.secure);
    this.setUploadUrl(this.config.upload.url, this.config.upload.secure);
    this.setSystemUrl(this.config.system.url, this.config.system.secure);
  }

  setConfig(key: string, value: string | null) {
    localStorage.setItem(key, value);
  }

  unsetConfig(key: string) {
    localStorage.removeItem(key);
  }

  getConfig(key: string) {
    return localStorage.getItem(key);
  }

  setUrl(url: string, secure: boolean = false): string {
    const protocol = secure ? 'https://' : 'http://';

    return protocol + url;
  }

  setApiUrl(url: string, secure: boolean = false) {
    this.setConfig('api.url', this.setUrl(url, secure));
    this.setConfig('api.secure', secure ? 'true' : 'false');
    this.setConfig('api.hostname', url);
  }

  setSokratesUrl(url: string, secure: boolean = false) {
    this.setConfig('sokrates.url', this.setUrl(url, secure));
    this.setConfig('sokrates.secure', secure ? 'true' : 'false');
    this.setConfig('sokrates.hostname', url);
  }

  setUploadUrl(url: string, secure: boolean = false) {
    this.setConfig('upload.url', this.setUrl(url, secure));
    this.setConfig('upload.secure', secure ? 'true' : 'false');
    this.setConfig('upload.hostname', url);
  }

  setSystemUrl(url: string, secure: boolean = false) {
    this.setConfig('system.url', this.setUrl(url, secure));
    this.setConfig('system.secure', secure ? 'true' : 'false');
    this.setConfig('system.hostname', url);
  }


  getApiUrl(service: string, url: string) {
    // if (service === 'tenant') {
    //   return `https://api.sokrates.xyz/${service}${url}`;
    // }
    return `${this.getConfig('api.url')}/${service}${url}`;
  }

  initTenant(tenant: { name: string, tenant_uuid: string }): void {
    if (!tenant) {
      return;
    }

    this.setConfig('client.name', tenant.name);
    this.setConfig('client.uuid', tenant.tenant_uuid);
  }

  initTheme(themes: Array<string>): void {
    if (typeof themes === 'string') {
      themes = JSON.parse(themes);
    }

    if (!themes) {
      themes = JSON.parse(this.getConfig('client.themes')) ?? this.themes;
    }

    // // validate theme exists
    // const theme = this.themes.find(i => i === name) ?? 'default';
    this.setConfig('client.themes', JSON.stringify(themes));

    const colorLevel = 9; // 9 warna, warna ke 5 itu primary
    for (let i = 1; i <= colorLevel; i++) {
      document
        .documentElement
        .style
        .setProperty(
          `--color-primary-${i}00`,
          `#${themes[i - 1]}`,
        );
    }
  }
}
