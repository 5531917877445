<div class="row">
    <div class="col-md-4 d-none d-md-block">
      <!-- <img src="/assets/img/login/Frame 26086712.svg" alt="image" class='col-6 rectangle-bg'> -->
      <!-- <img src="/assets/img/Rectangle 3048.png" alt="image" class='rectangle-bg'> -->
      <!-- <img src="/assets/img/Rectangle 3048.svg" alt="image" class='frame-1'> -->
      <img src="/assets/img/Frame 26086713.svg" alt="image" class='frame-1'>
      <!-- <img src="/assets/img/Ellipse 61.png" alt="image" class="ellipse-61">
      <img src="/assets/img/Ellipse 62.png" alt="image" class="ellipse-62">
      <img src="/assets/img/Ellipse 59.png" alt="image" class="ellipse-59"> -->
      <img src="/assets/img/Layer 2.svg" alt="image" class='layer-2'>
      <!-- <img src="/assets/img/Group-3.svg" alt="image" class="group-3">
      <img src="/assets/img/_x35__1_.svg" alt="image" class="people">
      <img src="/assets/img/Group-1.png" alt="image" class="group-1">
      <img src="/assets/img/Group-2.svg" alt="image" class="group-2">
      <img src="/assets/img/Group-4.png" alt="image" class="group-4">
      <img src="/assets/img/Group-5.svg" class="group-5"> -->
    </div>
    <div class="col-md-8 col-xs-12 px-4">
        <section class="py-4 py-xl-5 parent">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-xl-5 mx-auto mb-3" >
                        <img src="/assets/img/Layer 2.svg" alt="image" class="layer-1">
                        <span class="text-title">Content</span>
                        <span class="text-title">Management</span>
                        <span class="text-title">Service</span>
                    </div>
                </div>
                <div class="row mt-5 d-flex justify-content-center form-container">
                    <div class="col-md-6 col-xl-5">
                      <div class="card-body d-flex flex-column">
                        <div>
                            <h2 class="text-login">Login</h2>
                            <div class="text-welcome">Hi there, welcome back!</div>
                        </div>
                          <form #form="ngForm" [formGroup]="formLogin" ngForm>
                              <div class="mb-3">
                                <label for="username" class="text-form">Email Address</label>
                                <input class="form-control" type="username" name="username" id="username"
                                  formControlName="username"
                                  placeholder="Input email here">
                              </div>
                              <div class="mb-3 position-relative">
                                <label for="username" class="text-form">Password</label>
                                <input class="form-control" type="password" name="password" id="password"
                                  formControlName="password"
                                  placeholder="Input password here">
                                  <span class="password-toggle-icon" (click)="togglePasswordVisibility($event)" [style.background-image]="'url(' + eyeIconImages[currentIconIndex] + ')'"></span>
                              </div>
                              <div class="form-checkbox ">
                                <input class="form-check-input rounded-checkbox" type="checkbox" id="myCheckbox"><p class="text-checkbox">Remember me</p>
                              </div>
                              <p class="text-muted">Forgot password?</p>
                              <div class="mb-3"><button (click)="onSubmit()" class="btn btn-primary d-block w-100" type="submit" [disabled]="disableButton || loading.login" id="button">
                                <ng-container *ngIf="loading.login">
                                  <span class="spinner-border spinner-border-sm"></span>
                                </ng-container>
                                Login</button></div>
                          </form>
                      </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  </div>