import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable()
export class SettingService {

  constructor(
    private http: HttpClient,
  ) {
  }

  httpOptionWithToken() {
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.getToken()
      })
    };
  }

  getToken() {
    return localStorage.getItem('appToken');
  }

}
